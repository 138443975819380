<template>
    <div style="height: 31.38%">
        <h3 class="tw-text-xs tw-uppercase tw-text-blue-700">
            {{ $t('productProfile.branchList') }}
        </h3>
        <div class="tw-space-y-2">
            <Select
                v-model="model.other_branch_list"
                :filter-by-label="true"
                :placeholder="$t('select')"
                multiple
                :clearable="true"
                filterable
            >
                <Option
                    v-for="branch in branchList"
                    :value="branch.branch_id"
                    :key="branch.branch_id"
                >
                    {{ branch.branch_name_en }}
                </Option>
            </Select>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
export default {
    name: 'branchList',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {}
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('product/profile', ['formViewDatas']),
        branchList () {
            return this.formViewDatas.branch
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
