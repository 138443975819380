<template>
    <div>
        <ts-page-title
            :title="$t('productProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('productProfile.pageTitle'),
                    href: '/admin/product-profiles'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <div class="row">
                        <div class="col-md-4">
                            <ProductInfo
                                v-model="model"
                                :validate="errors"
                                @loading="value => (loading = value)"
                                class="tw-w-full tw-border tw-p-4 tw-rounded-lg tw-border-gray-200"
                            />
                        </div>
                        <div class="col-md-4">
                            <BranchList
                                v-model="model"
                                :validate="errors"
                                class="tw-w-full tw-border tw-p-4 tw-rounded-lg tw-border-gray-200"
                            />
                            <VendorList
                                v-model="model"
                                :validate="errors"
                                class="tw-w-full tw-border tw-p-4 tw-mt-4 tw-rounded-lg tw-border-gray-200"
                            />
                            <AddOnItem
                                v-model="model"
                                :validate="errors"
                                class="tw-w-full tw-border tw-p-4 tw-mt-4 tw-rounded-lg tw-border-gray-200"
                            />
                        </div>
                        <div class="col-md-4">
                            <ProductPhoto
                                v-model="model"
                                :validate="errors"
                                class="tw-w-full tw-border tw-p-4 tw-rounded-lg tw-border-gray-200"
                            />
                        </div>
                    </div>
                    <div class="row tw-pt-4">
                        <div class="col-md-12">
                            <ProSaleCodeList
                                v-model="model"
                                :validate="errors"
                            />
                        </div>
                    </div>
                    <div class="row tw-mt-4">
                        <div
                            class="col-md-12 tw-space-x-4 tw-flex tw-justify-end"
                        >
                            <ts-button
                                @click.prevent="
                                    $router.push({ name: 'product-profile' })
                                "
                                >{{ $t('cancel') }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                outline
                                :waiting="waiting_new"
                                @click.prevent="saveAddNew"
                                >{{ $t('save_add_new') }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                :waiting="waiting"
                                @click.prevent="save"
                                >{{ $t('save') }}</ts-button
                            >
                        </div>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions } from 'vuex'
import ProductPhoto from './components/product-photo.vue'
import ProductInfo from './components/product-info.vue'
import BranchList from './components/branch-list.vue'
import VendorList from './components/vendor-list'
import ProSaleCodeList from './components/pro-sale-code-list.vue'
import AddOnItem from './components/add-on-item'

export default {
    name: 'productProfile',
    components: {
        ProductPhoto,
        ProductInfo,
        BranchList,
        ProSaleCodeList,
        VendorList,
        AddOnItem
    },
    data () {
        return {
            errors: new Errors(),
            waiting_new: false,
            waiting: false,
            loading: false,
            file: null,
            model: {
                product_id: 0,
                category_id: null,
                product_name_kh: null,
                product_name_en: null,
                barcode: null,
                uom_cat_id: null,
                product_type_id: 2,
                pro_group_id: null,
                product_mode_id: 1,
                age_eligible_from: null,
                age_eligible_to: null,
                minimum_reorder_qty: null,
                maximum_reorder_allow: null,
                is_expirable: false,
                is_feature: false,
                vat_eligible: false,
                is_generate_unique_serial: false,
                photo: null,
                product_sale_code: [],
                other_branch_list: [],
                vendor_list: [],
                recipes: [],
                account_id: undefined
            }
        }
    },
    methods: {
        ...mapActions('product/profile', [
            'fetchBefore',
            'store',
            'upload',
            'formViewModel'
        ]),
        async uploadPhoto (id) {
            if (this.file instanceof File || this.file instanceof Blob) {
                if (id > 0) {
                    let formData = new FormData()
                    formData.append('photo', this.file)
                    let response = await this.upload({
                        id: id,
                        data: formData
                    })
                    console.log(response)
                }
            }
        },
        save () {
            let { photo, ...dataSend } = this.model
            this.file = photo
            this.waiting = true
            this.errors = new Errors()
            this.store(dataSend)
                .then(async response => {
                    await this.uploadPhoto(response.product_id)
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$router.push({ name: 'product-profile' })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        saveAddNew () {
            let { photo, ...dataSend } = this.model
            this.file = photo
            this.waiting_new = true
            this.errors = new Errors()
            this.store(dataSend)
                .then(async response => {
                    await this.uploadPhoto(response.id)
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        clearInput () {
            this.model.product_id = 0
            this.model.category_id = null
            this.model.product_name_kh = null
            this.model.product_name_en = null
            this.model.barcode = null
            this.model.uom_cat_id = null
            this.model.product_type_id = 2
            this.model.product_mode_id = 1
            this.model.age_eligible_from = null
            this.model.age_eligible_to = null
            this.model.minimum_reorder_qty = null
            this.model.maximum_reorder_allow = null
            this.model.is_expirable = false
            this.model.vat_eligible = false
            this.model.is_generate_unique_serial = false
            this.model.photo = null
            this.model.product_sale_code = []
            this.model.other_branch_list = []
            this.model.vendor_list = []
            this.model.recipes = []
            this.model.account_id = undefined
            this.file = null
            this.errors = new Errors()
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT',
                desc: not.text
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('product/profile/CLEAR_SETUP')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.loading = true
            vm.fetchBefore({ params: {}, index: undefined }).finally(
                () => (vm.loading = false)
            )
        })
    }
}
</script>
